import React from 'react';
import { Container, Row, Col, Breadcrumb} from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Helmet} from "react-helmet";
import {Link} from 'gatsby'
import PageWrapper from "../components/PageWrapper";
import { Post } from "../components/Core";
import DataTable from '../components/data/banque/banque'
import DataTableMobile from '../components/data/banque/banqueMobile'
import DataTablePro from '../components/data/banquePro/banquePro'
import DataTableMobilePro from '../components/data/banquePro/banqueProMobile'
import Disclosure from '../components/Disclosure'
import {StaticImage} from 'gatsby-plugin-image';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar, faCreditCard} from '@fortawesome/free-solid-svg-icons'
import { faBuilding} from '@fortawesome/free-regular-svg-icons'

function Accounting(props) {

	var d = new Date();
var month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
var n = month[d.getMonth()];
var y = d.getFullYear();

    return (
        <PageWrapper footerDark>
          <Helmet>
                <meta charSet="utf-8" />
                <title>43 comptes courants comparés - découvrez le meilleur compte courant</title>
                <meta name="description" content="Trouvez le meilleur compte courant en comparant les frais, cartes bancaires et avis des banques pour particuliers, autoentrepreneurs et sociétés." />
            </Helmet>
          <Header/>
          <Container fluid style={{background: 'white', marginTop: 68, paddingTop: 20, paddingBottom: 130}}>
          <Container style={{maxWidth: 1280, marginTop: 28}}>
         
          <Breadcrumb>
            <Breadcrumb.Item> <Link to={`/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Accueil</Link></Breadcrumb.Item>
            <Breadcrumb.Item active style={{ color: 'rgb(189 190 191)', fontWeight: 300}}>Compte courant</Breadcrumb.Item>
          </Breadcrumb>
  

          <h1 id='compareh1' style={{color: 'rgb(41, 49, 61)'}}><span>Compte Courant</span> - Classement 2021</h1>
          <h2 id='compareh2' style={{color: 'rgb(41, 49, 61)'}}>Trouvez le meilleur compte courant avec un comparatif tarifs et cartes bancaires (mise à jour {n} {y})</h2>
          <div id='onetwothreemobile'>
          <p id='onep' style={{color: 'rgb(41, 49, 61)'}}><span id='one' style={{background: 'rgb(229 34 79)', paddingLeft: 10, paddingRight: 10, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>1</span> - Utilisez le menu de notre <span style={{fontWeight: 700}}>comparateur compte courant</span> pour déterminer vos priorités : promo, carte gratuite, frais.</p>
          <p id='twop' style={{color: 'rgb(41, 49, 61)'}}><span id='two' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>2</span> - Comparez les offres par tarif de carte bancaire, et les offres promos disponible dès aujourd’hui.</p>
          <p id='threep' style={{color: 'rgb(41, 49, 61)'}}><span id='three' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>3</span> - Sélectionnez la <span style={{fontWeight: 700}}>meilleur compte courant</span>, celui qui correspond le plus à vos besoins.</p>
          </div>

          <Disclosure/>
          <Row style={{height: 70, marginTop: -7, marginBottom: -50}}>
          
          </Row>
          </Container>
          </Container>  


     
          <Container style={{maxWidth: 1280, marginTop: -130}}>
        
          <Tabs style={{maxWidth: 1234}}>
    <TabList style={{marginLeft: 5}}>
      <Tab style={{marginLeft: 0}}><FontAwesomeIcon icon={faChartBar} style={{marginTop: 0, marginBottom: 0, fontSize: 20, marginLeft: 5, marginRight: 10}}/> Comptes pour particuliers </Tab>
      <Tab style={{marginLeft: 0}}><FontAwesomeIcon icon={faBuilding} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> Auto-entrepreneurs</Tab>
      <Tab style={{marginLeft: 0}}><FontAwesomeIcon icon={faCreditCard} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> Sociétés (SARL, SAS, SA)</Tab>
     
    </TabList>

    <TabPanel>
      <DataTable row={[1,2,4,5,9,10,11,13, 14,15,16, 17,18,19,20,21,22,23,25,28,29,31,32,35,36,37]} what='comptescourants' id='wideTable' classement='TarifCarte' order={false}/>
      <DataTableMobile row={[1,2,4,5,9,10,11,13, 14,15,16, 17,18,19,20,21,22,23,25,28,29,31,32,35,36,37]} what='comptescourants' id='mobileTable' classement='TarifCarte' order={false}/>
    </TabPanel>
      <TabPanel>      
	  <DataTablePro type='Société' row={[35,15,2,16,36,18,30,9,38]} id='wideTable' classement='Note' order={true}/>
	<DataTableMobilePro type='Société' row={[35,15,2,16,36,18,30,9,38]}id='mobileTable' classement='Note' order={true}/>
    </TabPanel>
      <TabPanel>    
	  <DataTablePro type='Société' row={[12,4,18,9,19,30,20]} id='wideTable' classement='Note' order={true}/>
	<DataTableMobilePro type='Société' row={[12,4,18,9,19,30,20]}id='mobileTable' classement='Note' order={true}/>
    </TabPanel>

  </Tabs>
            <Row className="justify-content-center">
              <Col>
            
              {/* <Disclosure/> */}
            

       
              </Col>
            </Row>
             
          </Container>
          <Container style={{marginTop: 100, marginBottom : 100}}>
          <Post>
    
      
		  <h2>Comment choisir votre compte courant ?</h2>
		  <p>
Détenir le meilleur compte courant est devenu essentiel pour effectuer ses dépenses au quotidien. On estime que près de 99% de la population française en possède un.
</p>
<StaticImage
		  	src='../images/compte-courant.png' width={1200}
			alt='meilleure compte courant'
			placeholder='tracedSVG'
			/>
<p>
Il est important de bien choisir la banque dans laquelle vous allez y ouvrir un compte puisque ce dernier induit des frais qui peuvent être plus ou moins élevés selon les établissements.
</p>
<h2>À quoi sert un compte courant ?</h2>
<p>
Également appelé compte à vue, compte chèque ou compte de dépôt, c’est le compte qui permet de gérer son argent à court terme par la réalisation d’opérations débitrices et créditrices notamment des versements, virements, prélèvements, retraits, paiements ou encaissement de chèque. Ainsi, il permet de recevoir de l’argent mais aussi de le dépenser pour payer des factures ou votre loyer, par exemple.
</p><p>
L’ouverture d’un compte courant donne lieu à la signature d’une convention de compte entre le client et la banque. Ce contrat recense les conditions et modalités de son fonctionnement.
</p>
<h3>
Différents types de comptes courants</h3>

<h4>Compte individuel</h4>
<p>
C’est le compte courant le plus simple et fréquemment rencontré. Il est utilisé par un seul bénéficiaire;</p>

<h4>
Compte joint</h4>
<p>
Ce compte est souscrit conjointement par deux titulaires pouvant effectuer des opérations librement;</p>

<h4>Compte collectif</h4>
<p>Il est détenu par plusieurs bénéficiaires désignés au moment de l’ouverture;</p>
<p>Le compte indivis est quant à lui mis à disposition de plusieurs cotitulaires. Dans ce cas toute opération nécessitera l’accord de tous les bénéficiaires.</p>

<h4>Compte courant pro</h4>
<p>
C’est le compte bancaire de votre société. Une société (EURL, SARL, SAS, SA) doit obligatoirement avoir une compte pro pour déposer son capital social.
</p>

<h4>
Compte courant pro individuel</h4>
<p>
C’est le compte bancaire de votre autoentreprise. La loi oblige désormais les entreprises individuels (autoentrepreneurs et micro-entreprise) à avoir un compte séparé pour leur activité mais ce compte ne doit pas forcément être un compte bancaire pro. Cette séparation de comptes a un coût mais elle sera utile pour structurer votre activité.
</p>
<h3>
Une gestion au quotidien de votre compte courant</h3>
<p>Pour effectuer ses dépenses, le titulaire dispose de plusieurs moyens de paiement associés au compte comme une carte bancaire, un chéquier, les espèces ou les virements et prélèvements.
</p><p>
La gestion de votre compte peut se faire de différentes manières et dépend des services disponibles dans la banque choisie. Le bénéficiaire peut consulter son solde ou effectuer des opérations de virement ou prélèvements soit :
</p>
<ul>
<li>Banques traditionnelles : en agence ou grâce au service de banque à distance</li>
<li>Banques en ligne : sur internet</li>
<li>Néobanques : via une application mobile</li>
</ul>

<p>
La différence d’accès entre tous les types de banques tend progressivement à se rapprocher, cependant la volonté du “mobile first” des néobanques leur permettent généralement de fournir une application mobile plus performante (pour l’instant).
</p><p>
L’établissement bancaire doit aussi obligatoirement fournir un relevé bancaire mensuel comportant toutes les opérations de débit et de crédit effectuées sur le compte.
</p>
<h3>Les frais du compte courant</h3>
<p>
Avant d’entreprendre l’ouverture d’un compte, il est important de prendre en compte les différents frais liés puisque ceux-ci sont variables d’un établissement à un autre. Ces coûts peuvent être dus, entre autres, à la tenue du compte, à la cotisation d’une carte de paiement ou à des incidents.
</p><p>
Nous vous suggérons de répondre aux questions de suivantes pour mieux comparer :
</p>

<h4>
Quelles sont vos revenus ?</h4>
<p>
Certaine banques offrent des cartes bancaires gratuites selon votre niveau de revenus, d’autres sont sans condition.
</p>

<h4>
Avez-vous besoin d’un chéquier ?</h4>
<p>
Les chèques tendent aujourd’hui à disparaître cependant votre profession ou votre organisation personnelle peut encore vous obliger à détenir un chéquier. Si la plupart des banques en lignes offre une possibilité “chèque”, ce besoin aura un coût et sera un critère important pour limiter vos choix pour votre compte courant.
</p>

<h4>
Faites-vous beaucoup de retraits en espèces ?</h4>
<ul>
	<li>En zone euro</li>
	<li>Hors zone euro</li>
</ul>

<p>
La différence se fera souvent sur le “hors zone euro. Attention : si vous faites beaucoup de trajets hors de la zone euro, choisir la mauvaise banque peut vous coûter des milliers d’euros supplémentaires en frais de change et de retraits tous les ans. Les néobanques sont généralement meilleures pour ce type de transactions.
</p>

<h4>Faites-vous beaucoup de virements ?</h4>
<ul>
	<li>En zone euro</li>
	<li>Hors zone euro</li>
</ul>

<p>
En plus des frais de changes que nous retrouvons dans le retrait d’espèces hors de la zone euro, les banques peuvent aussi limiter le nombre de virements gratuits et ajouter des frais supplémentaire pour chaque envoi.
</p>

<h4>
La carte bancaire est-elle importante pour vous ?</h4>
<p>
Carte Visa, Mastercard, virtuelle, classic, premium, premier, gold, metal, infinite, world elite… vous avez le choix! Ajoutez à cela des designs personnalisés et des couleurs plus ou moins flashy et choisir une carte bancaire devient aussi difficile que de choisir des nouvelles chaussures. Chaque carte sera liée à des frais et des services de votre compte courant. Il est sera donc important de trouver un juste milieu entre design, et prestations.
</p>

<h4>Avez-vous besoin d’un compte courant avec un support client prioritaire ?</h4>
<p>
Les nouvelles banques prônent les réductions de coûts et le zéro guichet, mais parfois parler à quelqu’un ça simplifie tout. À vous de décider l’importance de ce critère. Les banques offrent souvent des formules avec support client prioritaire sous forme de ligne téléphonique VIP (même chez les néobanques).
</p>

<h4>
Souhaitez-vous avoir des assurances inclus dans votre compte bancaire ?</h4>
<p>
Assurance voyage, bagage, rapatriement : les assurances c’est le bonus offerts par les comptes courants avec cartes bancaires hauts de gamme.
</p>
</Post>

          </Container>
          <Footer/>
      </PageWrapper>
    )
}

export default Accounting
